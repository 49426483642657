import { type FormSubscriberDataType } from '@/features/forms/FormSubscriber/validationScheme';
import { type FormContactDataType } from '@/features/forms/FormContato/validationScheme';
import { type FormInfoBookingDataType } from '@/features/forms/FormInfoBooking/validationScheme';
import { type FormCreateJourneyDataType } from '@/features/forms/CrieSeuRoteiro/validationScheme';
import { type FormEmailShareDataType } from '@/features/forms/FormShareMail/validationScheme';

type ProductFieldType = {
  productId?: string | number;
  productName?: 'pacote' | 'hotel' | 'oferta';
  productTitle?: string;
};

type PhoneDataType = {
  phone?: string;
  phoneData?: {
    dialCode: string;
    areaCode: string;
    phone?: string; // User Input
    number: string; // Input Tel formatted
    iso2: string;
    countryName: string;
  };
};

type ParsedDataType = {
  _privacy_policy?: string;
  source?: string;
} & PhoneDataType;

export type FormPayloadDataType = FormSubscriberDataType &
  FormContactDataType &
  FormInfoBookingDataType &
  FormCreateJourneyDataType &
  FormEmailShareDataType &
  ParsedDataType &
  ProductFieldType;

export default function formPayloadParser<T>(
  data: FormPayloadDataType & T,
  formLegacy?: 'emailshare'
): FormPayloadDataType & T {
  const _token = document.querySelector('input[name="_token"]')?.getAttribute('value') || null;
  const _privacy_policy = document.querySelector('input[name="_privacy_policy"]')?.getAttribute('value') || null;
  // const source = document.querySelector('input[name="_source"]')?.getAttribute('value') || null;

  let parsed = {};

  if (formLegacy === 'emailshare') {
    let legacy: any = {};
    legacy.pacote = data.productId;
    legacy.from_name = data.senderName;
    legacy.from_email = data.senderEmail;
    legacy.to_name = data.receiverName;
    legacy.to_email = data.receiverEmail;
    legacy.message = data.message;
    parsed = legacy;
  } else {
    parsed = {
      _privacy_policy,
      ...data,
    };
  }

  if (data?.phoneData && data?.phone) {
    let phoneData = parsePhoneData(data as any);
    parsed = { ...parsed, phoneData };
  }

  // TODO: CLEAN THIS UP
  console.group('___PAYLOAD___');
  console.log(parsed);
  console.groupEnd();

  return { ...data, ...parsed };
}

function parsePhoneData({ phone, phoneData }: PhoneDataType) {
  let areaCode;
  if (phone && phoneData) {
    phoneData.number = phoneData.number.replace(/[\s\-()]/g, '');
    areaCode = phoneData.number.replace(/^\+/, '').replace(phoneData.dialCode, '').substring(0, 2);
    phoneData.areaCode = areaCode;
    phoneData.phone = phone;
    return phoneData;
  }
}
