import { useState } from 'react';
import { FeedbackHandlerType } from '@/features/forms/components/FeedbackHandler/FeedbackHandler';
import { fetchClient } from '@/helpers/browser';

// added by: KTDEV-176
export const redirectToThankyouPage = () => {
  setTimeout(() => {
    if (typeof window !== undefined) {
      document.location = '/obrigado';
    }
  }, 1000);
};

export default function useFeedbackHandler(redirect: boolean | string = true) {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackHandlerType>({} as FeedbackHandlerType);

  type SubmitRequestHandlerArg = {
    onSuccess?: {
      message: string | null;
    };
    bannerProps?: {
      appearance?: 'success' | 'error';
      showClose?: false;
      size?: 'large' | 'base';
    };
  };

  const resetFeedback = () => {
    setFeedback(() => ({
      status: null,
      data: {
        message: undefined,
        errors: undefined,
      },
      // bannerProps: {}
    }));
  };

  async function submitRequestHandler(endpoint: string, payload: object, customHandler?: SubmitRequestHandlerArg) {
    setSubmitting(() => true);
    try {
      const response = await fetchClient(endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
        // mode: 'same-origin',
      });

      if (response.ok) {
        // changed by: KTDEV-176
        if (redirect === true) {
          redirectToThankyouPage();
        } else if (typeof redirect === 'string') {
          return;
        } else {
          setFeedback({
            status: response.status,
            data: { message: customHandler?.onSuccess?.message || 'Sua mensagem foi enviada com sucesso!' },
            bannerProps: {
              appearance: 'success',
              showClose: false,
              size: 'large' /*closeTimeout: 4000*/,
              ...customHandler?.bannerProps,
            },
          });
        }
        setSubmitted(() => true);
        setSubmitting(() => false);
        // If all went write we return true to indicate the form submission was successful,
        // then we can call the reset from the resolved promise
        return true;
      } else {
        try {
          let data = await response.json();
          setFeedback({ status: response.status, data, bannerProps: { showClose: true, appearance: 'error' } });
        } catch (e) {
          setFeedback({
            status: response.status,
            data: { message: 'Parser Error' },
            bannerProps: { showClose: true, appearance: 'error' },
          });
        }
      }
    } catch (err) {
      setFeedback({
        status: 0,
        data: { message: err?.toString() || 'Unknown error' },
        bannerProps: { showClose: true, appearance: 'error' },
      });
    }
    setSubmitting(() => false);
  }

  return {
    feedback,
    submitting,
    submitted,
    setFeedback,
    setSubmitting,
    setSubmitted,
    submitRequestHandler,
    resetFeedback,
  };
}
