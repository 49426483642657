import * as z from 'zod';
import { VALIDATION_MESSAGES } from '@/features/forms/validation';

const validationScheme = z.object({
  source: z.string(),
  name: z.string().min(1, { message: VALIDATION_MESSAGES.required }),
  email: z.string().email({ message: VALIDATION_MESSAGES.email }),
});

export type FormSubscriberDataType = z.infer<typeof validationScheme>;
export default validationScheme;
