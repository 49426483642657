import React, { useEffect, useState } from 'react';
import Banner, { BannerProps } from '@/components/notification/Banner/Banner';

export interface FeedbackHandlerType {
  status: number | null;
  data: {
    message?: string;
    errors?: { [key: string]: string[] };
  };
  bannerProps?: Pick<BannerProps, 'appearance' | 'showIcon' | 'showClose' | 'size' | 'closeTimeout'> & {
    show?: boolean;
  };
}

function FeedbackHandler({ status, data, bannerProps, ...props }: FeedbackHandlerType) {
  const { errors, message } = data || {};
  const [error, setError] = useState<string[]>([]);
  const [title, setTitle] = useState<string | null>(null);

  useEffect(() => {
    switch (status) {
      case 0:
        setTitle('Error.');
        setError([data.message || '']);
        break;
      case 201:
        setTitle(data.message || '');
        setError(['']);
        break;
      case 412:
        setTitle('UID not found.');
        // setError(['Por favor atualize a página CTRL+F5.']);
        break;
      case 404: {
        let title = 'Desculpe. Ocorreu um erro.';
        let error = data?.message || 'Por favor tente novamente em alguns instantes ou fale com nosso suporte.';
        setTitle(title);
        setError([error]);
        break;
      }
      case 403: {
        let title = 'Error.';
        let error = data?.message || 'Sessão expirou por inatividade. Por favor atualize a página.';
        setTitle(title);
        setError([error]);
        break;
      }
      case 422:
        setTitle('Erros de preenchimento.');
        let obj: string[] = [];
        if (errors && Object.keys(errors).length) {
          Object.keys(errors).forEach((key: string) => {
            if (typeof errors[key][0] === 'string') {
              obj.push(errors[key][0] as string);
            }
          });
          if (obj.length) {
            setError(obj);
          }
        }
        break;

      case 419: {
        let error = data?.message || 'Sessão expirou. Por favor atualize a página.';
        setTitle('Sessão Expirada.');
        setError([error]);
        break;
      }
      case 429:
        setTitle('Limite de tentativas excedido.');
        setError(['aguarde alguns minutos e tente novamente.']);
        break;

      case 500:
        setTitle('Server error.');
        setError([data.message || '']);
        break;

      default:
        setTitle(null);
        setError([]);
    }
  }, [errors, status, title, data?.message]);

  return (
    <Banner show={!!error.length || !!title} appearance="error" {...(bannerProps as any)}>
      {title && <strong>{title}</strong>}
      <ul>
        {error.map((err) => (
          <li key={err}>
            <small>{err}</small>
          </li>
        ))}
      </ul>
    </Banner>
  );
}

export default FeedbackHandler;
