import { RD_STATION_TOKEN } from '@/config';
import { FormPayloadDataType } from '@/features/forms/formPayloadParser';

type FormEventName =
  | 'frmNewsletterSubscription'
  | 'frmInfoBooking'
  | 'form-criar-roteiro'
  | 'form-pacotes'
  | 'form-ofertas'
  | 'form-contato';

type GTMEventData = {
  hitType: 'event';
  eventCategory: 'Form' | 'Image';
  eventAction: 'submit' | 'view';
  eventLabel: string;
  eventValue?: number;
};

export const trackFBPixel = (formId: FormEventName, pageName: string) => {
  if (!window.fbq) {
    console.group('___trackFBPixel___');
    console.log({
      content_category: formId, // form-name
      content_name: pageName, // Page Name
    });
    console.groupEnd();
    return;
  }

  fbq('trackCustom', 'ConversionForm', {
    content_category: formId, // form-name
    content_name: pageName, // Page Name
  });
};

export const trackGTMEvent = ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
}: Omit<GTMEventData, 'hitType'>) => {
  try {
    if (!window.ga) {
      console.group('___trackGTMEvent___');
      console.log();
      console.log(`trackGTMEvent`, { eventCategory, eventAction, eventLabel, eventValue });
      console.log('trackGTMEvent GA not installed.');
      console.log(`window.ga`, window.ga);
      console.groupEnd();
      return;
    }

    const data: GTMEventData = {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
    };

    if (eventValue) {
      data.eventValue = eventValue;
    }

    // Tracking via Google TAG MANAGER
    if (typeof window.google_tag_manager === 'object') {
      ga('gtm1.send', data);
    } else if (typeof ga === 'function') {
      // Defaults to default GA snippet tracking code
      ga('send', data);
    }
  } catch (e) {
    console.log(`trackGTMEvent ERROR:`, e);
  }
};

export const trackRDStation = (formId: FormEventName, data: FormPayloadDataType | string) => {
  const rds_token = document.querySelector('meta[name="rds-token"]')?.getAttribute('content') || RD_STATION_TOKEN;

  if (!rds_token) {
    console.group('___trackRDStation____');
    console.log('formId', formId);
    console.log('data', data);
    console.error('rds_token not configured installed.', rds_token);
    console.groupEnd();
    return;
  }

  const payload: { name: string; value: string }[] = [
    { name: 'token_rdstation', value: rds_token },
    { name: 'identificador', value: formId },
  ];

  if (typeof data === 'string' && data.indexOf('@') >= 0) {
    payload.push({ name: 'email', value: data });
  } else if (typeof data === 'object') {
    // Form TravelBooking | TravelHotel | Offer
    // ----------------------------------------
    if (data.productName && data.productTitle) {
      payload.push({ name: data.productName, value: data.productTitle });
    }

    // Form Contact
    // ----------------------------------------
    if (data.contactType !== undefined) {
      payload.push({ name: 'tipo', value: data.contactType });
    }

    if (data.companyName !== undefined) {
      payload.push({ name: 'empresa', value: data.companyName });
    }

    if (data.subject !== undefined) {
      payload.push({ name: 'assunto', value: data.subject });
    }

    if (data.origin !== undefined) {
      payload.push({ name: 'origem', value: data.origin });
    }

    if (data.destination !== undefined) {
      payload.push({ name: 'destino', value: data.destination });
    }

    if (data.dateDepart !== undefined) {
      payload.push({ name: 'embarque', value: data.dateDepart });
    }

    if (data.paxNumber !== undefined) {
      payload.push({ name: 'passageiros', value: data.paxNumber });
    }

    // Form Criar Roteiro
    // ----------------------------------------
    if (data.travelReason !== undefined) {
      payload.push({ name: 'motivo', value: data.travelReason });
    }

    if (data.travelPlannedDate !== undefined) {
      payload.push({ name: 'data', value: data.travelPlannedDate });
    }

    if (data.travelDestinations !== undefined && data.travelDestinations.length) {
      payload.push({ name: 'destinos', value: data.travelDestinations.toString() });
    }

    if (data.travelCitiesToVisit !== undefined && data.travelCitiesToVisit.length) {
      payload.push({ name: 'cidades', value: data.travelCitiesToVisit.toString() });
    }

    if (data.travelExperiencies !== undefined && data.travelExperiencies.length) {
      payload.push({ name: 'experiencias', value: data.travelExperiencies.toString() });
    }

    if (data.travelSpeakEng !== undefined) {
      payload.push({ name: 'fala_ingles', value: data.travelSpeakEng });
    }

    if (data.travelLangPref !== undefined) {
      payload.push({ name: 'idioma_preferido', value: data.travelLangPref });
    }

    if (data.travelBudget !== undefined) {
      payload.push({ name: 'budget', value: data.travelBudget });
    }

    if (data.travelPaxAdtNumber !== undefined) {
      payload.push({ name: 'adultos', value: data.travelPaxAdtNumber });
    }

    if (data.travelPaxChdNumber !== undefined) {
      payload.push({ name: 'criancas', value: data.travelPaxChdNumber });
    }

    if (data.travelPaxInfNumber !== undefined) {
      payload.push({ name: 'bebes', value: data.travelPaxInfNumber });
    }

    if (data.travelAccommodationCategory !== undefined) {
      payload.push({ name: 'acomodacao_categoria', value: data.travelAccommodationCategory });
    }

    if (data.travelAccommodationType !== undefined) {
      payload.push({ name: 'acomodacao_tipo', value: data.travelAccommodationType });
    }

    if (data.purchasedFlight !== undefined) {
      payload.push({ name: 'passagem_comprada', value: data.purchasedFlight });
    }

    // Defaults
    // ----------------------------------------
    if (data.name !== undefined) {
      payload.push({ name: 'nome', value: data.name });
    }

    if (data.email !== undefined) {
      payload.push({ name: 'email', value: data.email });
    }

    if (data.phoneData?.areaCode !== undefined) {
      payload.push({ name: 'ddd', value: data.phoneData.areaCode });
    }

    if (data.phone !== undefined) {
      payload.push({ name: 'telefone', value: data.phoneData.number });
    }

    if (data.message !== undefined) {
      payload.push({ name: 'mensagem', value: data.message });
    }

    // Only applies to form newsletter
    if (formId === 'frmNewsletterSubscription') {
      if (data._privacy_policy !== undefined) {
        payload.push({ name: 'privacy_policy', value: '1' });
      }
    }

    if (data.subscribeNews !== undefined) {
      payload.push({ name: 'newsletter', value: data.subscribeNews ? 'Sim' : 'Não' });

      // in case the user has authorized to receive newsletter he also accepted the privacy policies
      payload.push({ name: 'privacy_policy', value: data.subscribeNews ? '1' : '0' });
    }

    if (data.source !== undefined) {
      payload.push({ name: 'origem', value: data.source });
    }
  }

  if (window.RdIntegration) {
    // Temporally tracker for RDS errors
    try {
      window.RdIntegration.post(payload);
    } catch (e) {
      // TODO: Should we really report this error?
      console.error('RDS POST REQUEST ERROR', e);
      // app.request.post('/rds-error', {
      //   title: 'RDS ERROR REPORT',
      //   javaScriptError: e.toString(),
      //   payload: payload
      // })
    }
  } else {
    console.log('RdIntegration is not installed', payload);
    let err;
    try {
      err = payload;
    } catch (e) {
      err = { jsError: e?.toString() };
    }
    // TODO: Should we really report this error?
    console.warn('RdIntegration is not installed', err);
    // app.request.post('/rds-error', {
    //   title: 'RdIntegration is not installed',
    //   // javaScriptError: e.toString(),
    //   payload: err,
    // });
  }
};
