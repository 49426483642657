import React, { useState } from 'react';
import TextField from '@/components/form/TextField/TextField';
import Button from '../../../components/button/Button/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import validationScheme from '@/features/forms/FormSubscriber/validationScheme';
import formPayloadParser, { FormPayloadDataType } from '@/features/forms/formPayloadParser';
import { FORMS_ENDPOINT } from '@/helpers/browser';
import useFeedbackHandler from '@/features/forms/hooks/useFeedbackHandler';
import FeedbackHandler from '@/features/forms/components/FeedbackHandler/FeedbackHandler';
import { trackRDStation } from '@/utils/tracking';

export interface FormSubscriberProps {
  source?: 'site' | 'blog';
}

export type FormSubscriberDataType = z.infer<typeof validationScheme>;

const FormSubscriber = ({ source = 'site', ...props }: FormSubscriberProps) => {
  const [complete, setComplete] = useState<'success' | 'error' | null>(null);
  const {
    watch,
    register,
    formState: { errors, isValid, isValidating, isSubmitted, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormSubscriberDataType>({
    resolver: zodResolver(validationScheme),
    defaultValues: {
      source,
      name: '',
      email: '',
    },
  });

  const { feedback, submitRequestHandler } = useFeedbackHandler(false);

  const onSubmit = async (data: FormSubscriberDataType) => {
    if (!isValid) {
      setComplete('error');
      return;
    }

    const payload = formPayloadParser(data as FormPayloadDataType);

    submitRequestHandler(FORMS_ENDPOINT.form_subscriber, payload, {
      onSuccess: {
        message: 'OK. Seu endereço de email foi adicionado com sucesso em nossa lista!',
      },
      bannerProps: {
        size: 'base',
      },
    }).then((done) => {
      done && reset();
      // =====================
      // FORM EVENT TRACKING
      // =====================
      trackRDStation('frmNewsletterSubscription', payload);
    });
  };

  return (
    <form
      action="/proxy/action/form-subscriber"
      name="frmNewsletterSubscription"
      id="frmNewsletterSubscription"
      method="post"
    >
      <FeedbackHandler {...feedback} />
      {complete !== 'success' && (
        <div>
          <TextField type="hidden" name="_privacy_policy" value="1" />
          <TextField type="hidden" {...register('source')} />
          <TextField type="text" placeholder="Nome Completo" {...register('name')} error={errors.name?.message} />
          <TextField type="email" placeholder="Email" {...register('email')} error={errors.email?.message} />
          <Button block type="button" onClick={handleSubmit(onSubmit as SubmitHandler<FormSubscriberDataType>)}>
            Assinar
          </Button>
        </div>
      )}
    </form>
  );
};

export default FormSubscriber;
