/**
 * --------------------------------------------------------------
 * --------------------------------------------------------------
 * Helper functions to be used only in the browser.
 *
 * **************************************************************
 * IMPORTANT: Don't call these functions within the SERVER!!
 * **************************************************************
 *
 *
 * --------------------------------------------------------------
 * */

export function isMobile(width: number | string = 576): boolean {
  let w = typeof width === 'number' ? `${width}px` : width;
  return typeof window !== 'undefined' && window.matchMedia(`only screen and (max-width: ${w})`).matches;
}

export const SITE_URL = process.env.NEXT_PUBLIC_APP_URL;
export const ACCOUNT_URL = process.env.NEXT_PUBLIC_APP_ACCOUNT_URL;

/**
 * NOTE: ONLY DON'T USE THIS FUNCTION FOR CLIENT-SIDE URL FORMATTING
 * */
export function siteUrl(pathname: string, absolute?: boolean): string {
  let path = pathname.replace(/^\//g, '').trim();

  return `${absolute ? SITE_URL : ''}/${path}`;
}

export function whatsAppSharer(title: string, permalink: string, mobile: boolean) {
  let text = `Conheça o pacote: ${title.replace(/&/g, 'e')} - ${siteUrl(permalink, true)}`;
  return mobile
    ? `whatsapp://send?text=${text}`
    : `https://api.whatsapp.com/send/?phone=&text=${text}&type=phone_number&app_absent=0`;
}

export function urlDestinos(slug?: string) {
  if (!slug) {
    return '/destinos';
  }
  slug = slug.replace(/^\//, '');
  return `/destinos/${slug}`;
}

export function url_ofertas(slug: string) {
  if (!slug) {
    return `/ofertas`;
  }
  return `/ofertas/${slug}.html`;
}

export const API_URL = {
  daily_exchange_rate: `${SITE_URL}/api/daily-exchange-rate`,
  popup: `${SITE_URL}/api/popup`,
  user: `${SITE_URL}/api/user`,
  logout: `${SITE_URL}/api/logout`,
};

export const FORMS_ENDPOINT = {
  form_subscriber: '/proxy/form/subscriber',
  form_info_booking: (form: 'pacote' | 'oferta') => `/proxy/form/info-booking/${form}`,
  form_email_share: `/proxy/form/emailshare`,
  form_contact: `/proxy/form/contato`,
  form_create_your_journey: `/proxy/form/crieseuroteiro `,
  form_login: `${ACCOUNT_URL}/auth/signin`,
  form_signup: `${ACCOUNT_URL}/auth/signup`,
  form_signup_complete: (uuid: string, signature: string) => `${ACCOUNT_URL}/auth/signup/${uuid}/${signature}`,
  form_forgot_password: `${ACCOUNT_URL}/auth/password/recovery/send`
};

export const ACCOUNT_LINK = {
  login: `/account/login`,
  signup: `/account/signup`,
  profile: `/account/profile`,
  forgot_password: `/account/password/recovery`,
  logout: `/account/logout` // <--- NOT A REAL PATH IN USE
};

export async function fetchClient(path: string, options?: RequestInit) {
  return await fetch(path, {
    credentials: 'include',
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
      ...options?.headers,
    },
  });
}
